<template>
  <HeaderSection />
  <TopNavigation />
  <router-view/>
  <FooterSection />
</template>

<script>
// @ is an alias to /src
import HeaderSection from '@/components/HeaderSection.vue'
import TopNavigation from '@/components/TopNavigation.vue'
import FooterSection from '@/components/FooterSection.vue'

export default {
  name: 'AppHome',
  components: {
    HeaderSection,
    TopNavigation,
    FooterSection
  }
}
</script>

<style>
</style>
