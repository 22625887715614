<template>
    <footer class="footer text-faded text-center py-5">
        <div class="container pt-4">
            <!-- Section: Social media -->
            <section class="mb-4">
                <a class="btn btn-link btn-floating btn-lg text-dark m-1" href="https://www.instagram.com/zandrasalterations/" target="_social"><img style="height: 35px;" src="@/assets/img/instagram.png" /></a>
                <a class="btn btn-link btn-floating btn-lg text-dark m-1" href="https://www.facebook.com/ZandrasAlteration/" target="_social"><img style="height: 35px;" src="@/assets/img/facebook.png" /></a>
            </section>
      </div>
        <div class="container"><p class="m-0 small">Copyright &copy; Syfabrik LLC 2024</p></div>

    </footer>
</template>