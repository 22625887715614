<template>
  <!-- Navigation-->
  <nav class="navbar navbar-expand-lg navbar-dark py-lg-4" id="mainNav">
    <div class="container">
      <a class="navbar-brand text-uppercase fw-bold d-lg-none" href="/">Syfabrik</a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mx-auto">
          <li class="nav-item px-lg-4">
            <router-link class="nav-link text-uppercase" to="/">Alterations</router-link>
          </li>
          <li class="nav-item px-lg-4">
            <router-link class="nav-link text-uppercase" to="/decor">Decor</router-link>
          </li>
          <li class="nav-item px-lg-4">
            <router-link class="nav-link text-uppercase" to="/products">Blankets</router-link>
          </li>
          <li class="nav-item px-lg-4">
            <router-link class="nav-link text-uppercase" to="/contracts">Commercial</router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>