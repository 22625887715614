<template>
  <div class="alterations">
    <AlterationsSection />
  </div>
</template>

<script>
// @ is an alias to /src
import AlterationsSection from '@/components/AlterationsSection.vue'

export default {
  name: 'AlterationsView',
  components: {
    AlterationsSection
  }
}
</script>
