<template>
    <section class="page-section clearfix">
        <div class="container">
            <div class="intro">
                <img class="intro-img img-fluid mb-3 mb-lg-0 rounded" src="@/assets/img/alterations.jpg" alt="..." />
                <div class="intro-text left-0 text-center bg-faded p-5 rounded">
                    <h2 class="section-heading mb-4">
                        <span class="section-heading-upper">Alterations</span>
                        <span class="section-heading-lower">by Zandra</span>
                    </h2>
                    <p class="mb-3">Through tremendous word of mouth support, Alterations by Zandra has grown to be Harford County's premier alterations provider.
                                
                                    Conveniently located in Bel Air, Maryland this service is provided by appointment only.  For large bridal parties we can come to you for a group fitting.
                                
                                Reach out through Facebook to schedule your fitting.</p>
                    <div class="intro-button mx-auto"><a class="btn btn-primary btn-xl" target="_facebook" href="https://www.facebook.com/ZandrasAlteration/">Schedule a Fitting</a></div>
                </div>
            </div>
        </div>
    </section>
    <section class="page-section cta">
        <div class="container">
            <div class="row">
                <div class="col-xl-9 mx-auto">
                    <div class="cta-inner bg-faded text-center rounded">
                        <h2 class="section-heading mb-4">
                            <span class="section-heading-upper">For your</span>
                            <span class="section-heading-lower">Special Day</span>
                        </h2>
<div class="grid-container">
<div class="grid-item"><img src="@/assets/collage/FB_IMG_1685387006854.jpg" alt="Image 1" class="grid-item"></div>
<div class="grid-item"><img src="@/assets/collage/IMG_20220816_144039591_MP.jpg" alt="Image 1" class="grid-item"></div>
<div class="grid-item"><img src="@/assets/collage/IMG_20221119_185641.jpg" alt="Image 1" class="grid-item"></div>
<div class="grid-item"><img src="@/assets/collage/IMG_20230508_193647.jpg" alt="Image 1" class="grid-item"></div>
<div class="grid-item"><img src="@/assets/collage/IMG_20230529_145945.jpg" alt="Image 1" class="grid-item"></div>
<div class="grid-item"><img src="@/assets/collage/PXL_20220326_184913911.jpg" alt="Image 1" class="grid-item"></div>
<div class="grid-item"><img src="@/assets/collage/PXL_20220423_161848548.jpg" alt="Image 1" class="grid-item"></div>
<div class="grid-item"><img src="@/assets/collage/PXL_20220428_203012371.jpg" alt="Image 1" class="grid-item"></div>
<div class="grid-item"><img src="@/assets/collage/PXL_20220504_183826498.jpg" alt="Image 1" class="grid-item"></div>
<div class="grid-item"><img src="@/assets/collage/PXL_20220505_002334610.jpg" alt="Image 1" class="grid-item"></div>
<div class="grid-item"><img src="@/assets/collage/PXL_20220507_003302217.jpg" alt="Image 1" class="grid-item"></div>
<div class="grid-item"><img src="@/assets/collage/PXL_20220507_214518652.jpg" alt="Image 1" class="grid-item"></div>
<div class="grid-item"><img src="@/assets/collage/PXL_20220919_203002620.jpg" alt="Image 1" class="grid-item"></div>
<div class="grid-item"><img src="@/assets/collage/PXL_20220925_175218058.jpg" alt="Image 1" class="grid-item"></div>
<div class="grid-item"><img src="@/assets/collage/PXL_20221027_162040445.jpg" alt="Image 1" class="grid-item"></div>
<div class="grid-item"><img src="@/assets/collage/PXL_20221102_223907233.jpg" alt="Image 1" class="grid-item"></div>
</div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<style scoped>
  .grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 15px;
  }
  
  .grid-item {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px; /* Added rounded corners */
  }
</style>