import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import 'bootstrap/dist/css/bootstrap.css'
import * as bootstrap from 'bootstrap/dist/js/bootstrap.bundle'
window.bootstrap = bootstrap;
import './css/styles.css'


createApp(App).use(router).mount('#app')
